<template>
  <div>
    <div class="row mb-2">
      <div class="col-12">
        <h4 class="mb-3 text-center">Overview of PSC inspection by country by year</h4>
      </div>
      <div class="col-1 text-left">
        <label for="month" class="col-form-label">YEAR</label>
      </div>
      <div class="col-2">
        <select name="month" id="month" class="form-control form-control-sm" v-model="params.year">
          <option :value="null">--- SELECT YEAR ---</option>
          <option :value="year" v-for="year in years">{{year}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table text-center table-sm small e-tbl">
          <thead class="bg-white">
          <tr>
            <th class="align-middle" scope="col">Year</th>
            <th class="align-middle" scope="col">Country</th>
            <th class="align-middle" scope="col">Nbr Inspection</th>
            <th class="align-middle" scope="col">Deficiency Inspection</th>
            <th class="align-middle" scope="col">No Deficiency Inspection</th>
            <th class="align-middle" scope="col">NDR (%)</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="loading">
            <tr>
              <td colspan="100%">
                <app-loading></app-loading>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="report in newInspectionByCountry ">
              <td>{{params.year}}</td>
              <td>{{report.country}}</td>
              <td>{{ report.inspection_count }}</td>
              <td>{{report.with_deficiency_count}}</td>
              <td>{{report.no_deficiency_count}}</td>
              <td>{{report.no_deficiency_rate}}</td>
            </tr>
          </template>

          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AppLoading from "@/components/elements/AppLoading.vue";
import _ from "lodash"

export default {
  name: "PscInspectionByCountry",
  components: {AppLoading},
  data(){
    return {
      loading:false,
      params:{
        year:new Date().getFullYear()
      },
      years:Array.from({length:100},(_,i)=>new Date().getFullYear() - i)
    }
  },
  computed:{
    ...mapGetters(['inspectionByCountry']),
    newInspectionByCountry() {
      if (this.params.year === null) {
        return [];
      }
      return this.inspectionByCountry;
    }
  },
  methods:{
    ...mapActions(['getInspectionReportByCountry']),
    async getData(){
      this.loading=true
      await this.getInspectionReportByCountry(this.params)
      this.loading=false
    }
  },
  created(){
   this.getInspectionByCountry=_.debounce(this.getData,250)
    this.getData()
  },

  watch:{
    "params.year"(){
      if (this.params.year !== null) {
        this.getInspectionByCountry()
      }
    }
  }

}
</script>


<style scoped>

</style>
